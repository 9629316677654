import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface BookingState {
  guest: number;
  date: string;
  time: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  request: string;
  organizationDetails: any;
  shiftDetails: any;
  availableSpots: any;
  availableDaySpots: any;
  tableDuration: number;
  bookingDetails: any;
  locationId: string;
  floorData: any[];
  bookingStatus: any[];
  bookingTags: any[];
  allocatedTables: any[];
}

const initialState: BookingState = {
  guest: 0,
  date: new Date().toDateString(),
  time: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  request: "",
  organizationDetails: JSON.parse(localStorage.getItem("org") || "{}"),
  shiftDetails: JSON.parse(localStorage.getItem("shifts") || "[]"),
  availableSpots: [],
  availableDaySpots: [],
  tableDuration: 0,
  bookingDetails: [],
  locationId: localStorage.getItem("wrlId") || "",
  floorData: [],
  bookingStatus: JSON.parse(localStorage.getItem("status") || "[]"),
  bookingTags: JSON.parse(localStorage.getItem("tags") || "[]"),
  allocatedTables: [],
};
const bookingSlice = createSlice({
  name: "bookingReducer",
  initialState,
  reducers: {
    setGuest(state, action: PayloadAction<number>) {
      state.guest = action.payload;
    },
    setDate(state, action: PayloadAction<string>) {
      state.date = action.payload;
    },
    setTime(state, action: PayloadAction<string>) {
      state.time = action.payload;
    },
    setFirstName(state, action: PayloadAction<string>) {
      state.firstName = action.payload;
    },
    setLastName(state, action: PayloadAction<string>) {
      state.lastName = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setPhoneNumber(state, action: PayloadAction<string>) {
      state.phoneNumber = action.payload;
    },
    setRequest(state, action: PayloadAction<string>) {
      state.request = action.payload;
    },
    setOrganizationalDetails(state, action: PayloadAction<any>) {
      state.organizationDetails = action.payload;
    },
    setShiftDetails(state, action: PayloadAction<any>) {
      state.shiftDetails = action.payload;
    },
    setAvailableSpots(state, action: PayloadAction<any>) {
      state.availableSpots = action.payload;
    },
    setAvailableDaySpots(state, action: PayloadAction<any>) {
      state.availableDaySpots = action.payload;
    },
    setTableDuration(state, action: PayloadAction<number>) {
      state.tableDuration = action.payload;
    },
    setBookingDetails(state, action: PayloadAction<any>) {
      state.bookingDetails = action.payload;
    },
    setLocationId(state, action: PayloadAction<string>) {
      state.locationId = action.payload;
    },
    setFloorData(state, action: PayloadAction<[]>) {
      state.floorData = action.payload;
    },
    setBookingStatus(state, action: PayloadAction<[]>) {
      state.bookingStatus = action.payload;
    },
    setBookingTags(state, action: PayloadAction<[]>) {
      state.bookingTags = action.payload;
    },
    setAllocatedTables(state, action: PayloadAction<any[]>) {
      state.allocatedTables = action.payload;
    },
  },
});
export const {
  setDate,
  setTime,
  setGuest,
  setEmail,
  setFirstName,
  setLastName,
  setPhoneNumber,
  setRequest,
  setOrganizationalDetails,
  setShiftDetails,
  setAvailableSpots,
  setAvailableDaySpots,
  setTableDuration,
  setBookingDetails,
  setLocationId,
  setFloorData,
  setBookingStatus,
  setBookingTags,
  setAllocatedTables,
} = bookingSlice.actions;
export default bookingSlice.reducer;
