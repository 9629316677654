import moment from "moment";

const splitTimeRange = (
  startTime: string,
  endTime: string,
  interval: number
): string[] => {
  const start = moment(startTime, "HH:mm");
  const end = moment(endTime, "HH:mm");
  const timeSlots: string[] = [];

  while (start <= end) {
    timeSlots.push(start.format("HH:mm"));
    start.add(interval, "minutes");
  }

  return timeSlots;
};

export const getTimeSlotsForDate = (
  date: string,
  selectedParty: number,
  shiftData: any
): any[] => {
  const timeSlots: any[] = [];
  const dayName = moment(date).format("dddd").toUpperCase();
  const formattedDate = moment(date).format("YYYY-MM-DD");
  shiftData.forEach((shift: any) => {
    const shiftActiveDay = shift.ShiftActiveDay.find((shiftDay: any) => {
      return shiftDay.Day === dayName || shiftDay.Day === formattedDate;
    });
    if (
      shiftActiveDay &&
      selectedParty >= shift.ShiftAvailability.ReservationCover.Minimum &&
      selectedParty <= shift.ShiftAvailability.ReservationCover.Maximum
    ) {
      const slots = splitTimeRange(
        shiftActiveDay.StartTime,
        shiftActiveDay.EndTime,
        shift.ShiftAvailability.Timeslot
      );
      timeSlots.push(...slots);
    }
  });
  return timeSlots;
};

export const getReservationCovers = (
  selectedDate: string,
  shiftData: any
): number[] => {
  const filteredShifts = shiftData.filter((shift: any) => {
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    const dayName = moment(selectedDate).format("dddd").toUpperCase();
    return shift.ShiftActiveDay.some((shiftDay: any) => {
      return shiftDay.Day === dayName || shiftDay.Day === formattedDate;
    });
  });
  if (filteredShifts.length === 0) {
    return [];
  }

  const allMinimums = filteredShifts.map(
    (shift: any) => shift.ShiftAvailability.ReservationCover.Minimum
  );
  const allMaximums = filteredShifts.map(
    (shift: any) => shift.ShiftAvailability.ReservationCover.Maximum
  );

  const min = Math.min(...allMinimums);
  const max = Math.max(...allMaximums);

  const numberArray: number[] = [];
  for (let i = min; i <= max; i++) {
    numberArray.push(i);
  }

  return numberArray;
};
export const convertTime = (isoString: string) => {
  const date = new Date(isoString);
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const getLocations = (data: any) => {
  let resultArray = data.reduce((acc: any, location: any) => {
    const orgName = location.OrganizationName;
    if (!acc[orgName]) {
      acc[orgName] = [];
    }
    acc[orgName].push(location);
    return acc;
  }, {});
  return resultArray;
};
export const convertDate = (isoString: string) => {
  const date = new Date(isoString);
  return date;
};
