import React, { useState } from "react";
import "./InputComponentStyles.scss";
import eye from "../../assets/eye.png";
import hiddenImg from "../../assets/hidden.png";

interface AuthInputProps {
  value: string;
  label: string;
  onChange: (value: string) => void;
  type: string;
  error: string;
}

const AuthInputComponent: React.FC<AuthInputProps> = ({
  value,
  label,
  onChange,
  type,
  error,
}) => {
  const [hidden, setHidden] = useState(true);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className='Auth'>
      <div className='auth-input-component'>
        <input
          type={type === "password" ? (hidden ? "password" : "text") : type}
          id={label}
          value={value}
          onChange={handleChange}
          placeholder={label}
        />
        {type === "password" && (
          <img
            alt=''
            src={`${hidden ? eye : hiddenImg}`}
            onClick={() => {
              setHidden(!hidden);
            }}
          />
        )}
      </div>
      {error && <span className='error'>{error}</span>}
    </div>
  );
};

export default AuthInputComponent;
