import React from "react";
import "./CommentModalStyles.scss";
import close from "../../../../assets/close.png";
import TextArea from "../../../textarea/TextArea";

interface CommentsModalProps {
  isOpen: boolean;
  onClose: () => void;
  comment: string;
  setComment: React.Dispatch<React.SetStateAction<string>>;
}

const CommentModal: React.FC<CommentsModalProps> = ({
  isOpen,
  onClose,
  comment,
  setComment,
}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className='comment-modal-overlay' onClick={onClose}>
      <div
        className='comment-modal-content'
        onClick={(e) => e.stopPropagation()}
      >
        <div className='header'>
          <img alt='' src={close} onClick={onClose} />
        </div>
        <div className='content'>
          <TextArea
            label='Add Comments'
            value={comment}
            onChange={(value) => {
              setComment(value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CommentModal;
