import React from "react";
import "./DateModalStyles.scss";
import close from "../../../../assets/close.png";
import DatePickerComponet from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface DateModalProps {
  isOpen: boolean;
  onClose: () => void;
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<any>>;
  setDateOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
}

const DateModal: React.FC<DateModalProps> = ({
  isOpen,
  onClose,
  date,
  setDate,
  setDateOpen,
  isEdit,
}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className='date-modal-overlay' onClick={onClose}>
      <div
        className='date-modal-content'
        onClick={(e) => e.stopPropagation()}
        style={{ left: isEdit ? "37.2%" : "", top: isEdit ? "2rem" : "" }}
      >
        <div className='header'>
          <img alt='' src={close} onClick={onClose} />
        </div>
        <div className='content'>
          <DatePickerComponet
            selected={date}
            onChange={(date) => {
              setDate(date);
              setDateOpen(false);
            }}
            dateFormat={"dd LLLL YYYY"}
            open={true}
            minDate={new Date()}
            className='iner'
          />
        </div>
      </div>
    </div>
  );
};

export default DateModal;
