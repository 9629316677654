import React from "react";
import "./BaseHeaderStyles.scss";
import logo from "../../assets/logo.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../application/store";

const BaseHeader: React.FC = () => {
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  return (
    <div
      className='Baseheader'
      style={{ backgroundImage: ` url(${organizationDetails.BannerImage}) ` }}
    >
      <div className='logo-container'>
        <img alt='' src={logo} />
      </div>
      <div className='details'>
        <div className='header'>
          {organizationDetails?.OrganizationDetails?.Name}
        </div>
        <div className='sub-header'>
          {organizationDetails?.Address?.AddressLine01},{" "}
          {organizationDetails?.Address?.City},{" "}
          {organizationDetails?.Address?.PostalCode}
        </div>
      </div>
    </div>
  );
};
export default BaseHeader;
