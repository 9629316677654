import React, { useEffect, useState } from "react";
import "./FloorDetailsStyles.scss";
import back from "../../../../assets/arrow.png";
import email from "../../../../assets/email.png";
import more from "../../../../assets/more.png";
import edit from "../../../../assets/pencil.png";
import tag from "../../../../assets/tag.png";
import man from "../../../../assets/man.png";
import notes from "../../../../assets/notes.png";
import note from "../../../../assets/notepad.png";
import comment from "../../../../assets/comment.png";
import calendar from "../../../../assets/clIC.png";
import time from "../../../../assets/alarm.svg";
import covers from "../../../../assets/friends.png";
import duration from "../../../../assets/duration.png";
import table from "../../../../assets/cover.svg";
import status from "../../../../assets/status.png";
import est from "../../../../assets/milestone.png";
import reserve from "../../../../assets/restaurant.svg";
import Button from "../../../../components/button/Button";
import moment from "moment";
import NumberInput from "../../../../components/ReservationComponents/numberInput/NumberInput";
import { useDispatch } from "react-redux";
import { setAllocatedTables } from "../../../../../application/reducer/bookingSlice";
import DurationInput from "../../../../components/ReservationComponents/numberInput/DurationInput";
import TimeInput from "../../../../components/ReservationComponents/numberInput/TimeInput";
import DateModal from "../../../../components/ReservationComponents/modal/dateModal/DateModal";
import StatusModal from "../../../../components/ReservationComponents/modal/statusModal/StatusModal";
import DurationModal from "../../../../components/ReservationComponents/modal/durationModal/DurationModal";
import TimeModal from "../../../../components/ReservationComponents/modal/timeModal/TimeModal";
import CoverModal from "../../../../components/ReservationComponents/modal/coverModal/CoverModal";
import { updateReservation } from "../../../../../infra/apis/bookings/requests/Booking";
interface FloorDetailsProps {
  isModalOpen: boolean;
  onClose: () => void;
  tableData: any;
  setSelectedTables: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FloorDetails: React.FC<FloorDetailsProps> = ({
  isModalOpen,
  onClose,
  tableData,
  setSelectedTables,
  setIsModalOpen,
}) => {
  const [guest, setGuest] = useState<number>(
    tableData ? tableData.BookingDetails?.NumberOfPeople : 0
  );
  const [time, setTime] = useState<string>("0 min");
  const [interval, setInterval] = useState<number>(15);
  const [statusValue, setStatusValue] = useState<any>(
    tableData ? tableData.StatusInfo : ""
  );
  const [bookingTime, setBookingTime] = useState<any>(
    tableData
      ? tableData.BookingDetails?.BookingTime
      : moment(new Date()).format("HH:mm")
  );
  const [date, setDate] = useState<any>(
    tableData ? new Date(tableData.BookingDetails.BookingDate) : new Date()
  );

  const [isDatOpen, setDateOpen] = useState<boolean>(false);
  const [isTimeOpen, setTimeOpen] = useState<boolean>(false);
  const [isCoversOpen, setCoversOpen] = useState<boolean>(false);
  const [isDurationOpen, setDurationOpen] = useState<boolean>(false);
  const [isStatusOpen, setStatusOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const convertMinutesToTime = (totalMinutes: any) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    let timeString = "";
    if (hours > 0) {
      timeString += `${hours} h `;
    }
    if (minutes > 0) {
      timeString += `${minutes} mins`;
    }

    return timeString.trim();
  };
  useEffect(() => {
    setTime(convertMinutesToTime(tableData?.BookingDetails?.Duration));
    setGuest(tableData?.BookingDetails?.NumberOfPeople);
    setStatusValue(tableData?.StatusInfo);
    setBookingTime(tableData?.BookingDetails?.BookingTime);
    setDate(new Date(tableData?.BookingDetails?.BookingDate));
  }, [tableData]);
  const dispatch = useDispatch();

  const [tab, setTab] = useState<string>("DETAILS");
  if (!isModalOpen) {
    return null;
  }

  const detail = [
    {
      head: "Date",
      sub: moment(date).format("ddd,MMMM DD"),
      icon: calendar,
      isOpen: isDatOpen,
    },
    {
      head: "Time",
      sub: (
        <TimeInput
          value={bookingTime}
          setValue={setBookingTime}
          setTimeOpen={
            // setTimeOpen
            () => {}
          }
        />
      ),
      icon: time,
      isOpen: isTimeOpen,
    },
    {
      head: "Covers",
      sub: (
        <NumberInput
          value={guest}
          setValue={setGuest}
          setCoversOpen={
            // setCoversOpen
            () => {}
          }
        />
      ),
      icon: covers,
      isOpen: isCoversOpen,
    },
    {
      head: "Duration",
      sub: (
        <DurationInput
          value={time}
          setValue={setTime}
          interval={interval}
          setDurationOpen={
            // setDurationOpen
            () => {}
          }
        />
      ),
      icon: duration,
      isOpen: isDurationOpen,
    },
    {
      head: "Table",
      sub: (
        <div style={{ columnGap: "0.5rem", display: "flex" }}>
          {tableData?.AssetAllocation?.map((table: any) => {
            return <div>{table.Name}</div>;
          })}
        </div>
      ),
      icon: table,
    },
    {
      head: "Status",
      sub: statusValue !== "" ? statusValue?.DisplayName : "",
      icon: status,
      isOpen: isStatusOpen,
    },
    // {
    //   head: "Wait Est.",
    //   sub: "0 mins",
    //   icon: est,
    // },
    {
      head: "Reserve ID",
      sub: `# ${tableData.ConfirmationNumber}`,
      icon: reserve,
    },
  ];
  const data = [
    {
      head: "Guest Tags",
      sub: "Select tags",
      icon: tag,
    },
    {
      head: "Reservation",
      sub: "Select tags",
      icon: tag,
    },
    {
      head: "Special Requests",
      sub:
        tableData.ReservationNotes !== "" ? tableData.ReservationNotes : "None",
      icon: note,
    },
    {
      head: "Private Comments",
      sub: "Write comments only your team can see",
      icon: comment,
    },
    {
      head: "Guest Notes",
      sub: "Add internal guest notes here",
      icon: notes,
    },
    {
      head: "Employee",
      sub: "Select reservation taker",
      icon: man,
    },
    {
      head: "Fields:",
      sub: "Add additional details",
      icon: note,
    },
  ];
  const profileDetails = [
    {
      head: "First Name",
      sub: tableData.CustomerDetails.FirstName,
    },
    {
      head: "Last Name",
      sub: tableData.CustomerDetails.LastName,
    },
    {
      head: "Gender",
      sub: "---",
    },
    {
      head: "Date of Birth",
      sub: "---",
    },
    {
      head: "Anniversary",
      sub: "---",
    },
    {
      head: "Title",
      sub: "---",
    },
    {
      head: "Phone",
      sub: `${tableData.CustomerDetails.ContactDetails[0].PhoneCode} ${tableData.CustomerDetails.ContactDetails[0].Number}`,
    },
    {
      head: "Email",
      sub: tableData.CustomerDetails.Email,
    },
    {
      head: "Job Title",
      sub: "---",
    },
    {
      head: "Company",
      sub: "---",
    },
  ];
  const profileData = [
    {
      head: "Total Spend",
      sub: 0,
    },
    {
      head: "Spend Per Cover",
      sub: 0,
    },
    {
      head: "Upcoming Reservations",
      sub: 1,
    },
    {
      head: "Materialised Reservations",
      sub: 0,
    },
    {
      head: "Denied",
      sub: 0,
    },
    {
      head: "No Show",
      sub: 0,
    },
    {
      head: "Cancelled",
      sub: 0,
    },
    {
      head: "Review",
      sub: 0,
    },
  ];
  const handleItemOnClick = (item: string) => {
    // switch (item) {
    //   case "Status":
    //     setStatusOpen(!isStatusOpen);
    //     break;
    //   case "Date":
    //     setDateOpen(!isDatOpen);
    //     break;
    //   case "Time":
    //     setTimeOpen(!isTimeOpen);
    //     break;
    //   default:
    //     break;
    // }
  };
  const getLeftContent = () => {
    if (tab === "DETAILS") {
      return (
        <div className='inner-container'>
          {detail.map((item) => {
            return (
              <div
                className={`item ${item.isOpen ? "item-sel" : ""}`}
                onClick={() => {
                  if (item.head === "Table") {
                    dispatch(setAllocatedTables(tableData?.AssetAllocation));
                    setSelectedTables(true);
                    setIsModalOpen(false);
                  }
                }}
              >
                <div className='det'>
                  <div className='head'>
                    <img alt='' src={item.icon} />
                    {item.head}
                  </div>
                  <div className='sub'>{item.sub}</div>
                </div>
                {item.head !== "Covers" &&
                  item.head !== "Duration" &&
                  item.head !== "Time" &&
                  item.head !== "Reserve ID" && (
                    <div
                      className='arrow-cont'
                      onClick={() => handleItemOnClick(item.head)}
                    >
                      <img alt='' src={back} />
                    </div>
                  )}
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className='inner-container'>
          {profileDetails.map((item) => {
            return (
              <div className='item'>
                <div className='det'>
                  <div className='head'>{item.head}</div>
                  <div className='sub-gr'>{item.sub}</div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };
  const getRightContent = () => {
    if (tab === "DETAILS") {
      return (
        <div className='inner-container'>
          {data.map((item) => {
            return (
              <div className='item'>
                <div className='det'>
                  <div className='head'>
                    <img alt='' src={item.icon} />
                    {item.head}
                  </div>
                  <div className='sub'> {item.sub}</div>
                </div>
                <div className='arrow-cont'>
                  <img alt='' src={back} />
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className='inner-container'>
          {profileData.map((item) => {
            return (
              <div className='item'>
                <div className='det'>
                  <div className='head'>{item.head}</div>
                  <div className='sub'>{item.sub}</div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };
  return (
    <div
      className='FloorDetails-overlay'
      onClick={() => {
        dispatch(setAllocatedTables([]));
        onClose();
      }}
    >
      <div
        className='FloorDetails-content'
        onClick={(e) => e.stopPropagation()}
      >
        <div className='header-container-new'>
          <div className='header'>
            <img
              alt=''
              src={back}
              onClick={() => {
                dispatch(setAllocatedTables([]));
                onClose();
              }}
            />
            Reservation Details
          </div>
          <div className='button-container'>
            <Button
              auth
              text='Save'
              height='40px'
              padding='0.75rem 2rem'
              onClick={() => {
                setIsLoading(true);
                updateReservation(tableData).then(() => {
                  dispatch(setAllocatedTables([]));
                  setIsLoading(false);
                });
              }}
              loading={isLoading}
            />
          </div>
        </div>
        <div className='profile-details'>
          <div className='profile'>
            <div className='avatar'>
              {tableData.CustomerDetails.FirstName.charAt(0)}
              <div className='edit'>
                <img alt='' src={edit} />
              </div>
            </div>
            <div className='name-conatiner'>
              <div className='name'>
                {" "}
                {tableData.CustomerDetails.FirstName}{" "}
                {tableData.CustomerDetails.LastName}
              </div>
              <div className='email'>
                <img alt='' src={email} />
                <td> {tableData.CustomerDetails.Email}</td>
              </div>
            </div>
          </div>
          <div className='more'>
            <img alt='' src={more} />
          </div>
        </div>
        <div className='tab-bar'>
          <div
            className={`item ${tab === "DETAILS" ? "selected" : ""} `}
            onClick={() => setTab("DETAILS")}
          >
            Details
          </div>
          <div
            className={`item ${tab === "PROFILE" ? "selected" : ""} `}
            onClick={() => setTab("PROFILE")}
          >
            Profile
          </div>
        </div>
        <div className='container'>
          <div className='left-container'>{getLeftContent()}</div>
          <div className='right-container'>{getRightContent()}</div>
        </div>
      </div>
      {isDatOpen && (
        <DateModal
          date={date}
          setDate={setDate}
          setDateOpen={setDateOpen}
          isOpen={isDatOpen}
          onClose={() => {
            setDateOpen(false);
          }}
          isEdit={true}
        />
      )}
      {isStatusOpen && (
        <StatusModal
          isOpen={isStatusOpen}
          onClose={() => {
            setStatusOpen(false);
          }}
          setStatus={setStatusValue}
          isEdit={true}
        />
      )}
      {isDurationOpen && (
        <DurationModal
          isOpen={isDurationOpen}
          onClose={() => {
            setDurationOpen(false);
          }}
          interval={interval}
          setInterval={setInterval}
          isEdit={true}
        />
      )}
      {isCoversOpen && (
        <CoverModal
          isOpen={isCoversOpen}
          onClose={() => {
            setCoversOpen(false);
          }}
          cover={guest}
          setCover={setGuest}
          isEdit={true}
        />
      )}
      {isTimeOpen && (
        <TimeModal
          time={bookingTime}
          setTime={setBookingTime}
          isOpen={isTimeOpen}
          onClose={() => {
            setTimeOpen(false);
          }}
          isEdit={true}
        />
      )}
    </div>
  );
};

export default FloorDetails;
