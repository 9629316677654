import React from "react";
import MuiPhoneNumber from "material-ui-phone-number";
import "./InputComponentStyles.scss";

interface NumberInputComponentProps {
  value: string;
  label: string;
  onChange: any;
  fontSize?: string;
}

const NumberInputComponent: React.FC<NumberInputComponentProps> = ({
  value,
  label,
  onChange,
  fontSize,
}) => {
  return (
    <div className='number-input-component'>
      {label !== "" && (
        <div className='label'>
          {label} <span style={{ color: "red", marginLeft: "0.2rem" }}>*</span>
        </div>
      )}
      <div className='num-input'>
        <MuiPhoneNumber
          defaultCountry={"gb"}
          onlyCountries={["gb"]}
          onChange={onChange}
          value={value}
          style={{ width: "110%", fontSize }}
        />
      </div>
    </div>
  );
};

export default NumberInputComponent;
