import React, { useEffect, useState } from "react";
import down from "../../../assets/down-white.png";
import "./NumberInputStyles.scss";

interface DurationInputProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setDurationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  interval: number;
}

const DurationInput: React.FC<DurationInputProps> = ({
  value,
  setValue,
  interval,
  setDurationOpen,
}) => {
  const [time, setTime] = useState(interval);

  const formatTime = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours > 0 ? `${hours} h ${minutes} mins` : `${minutes} mins`;
  };
  useEffect(() => {
    setTime(interval);
  }, [interval]);
  useEffect(() => {
    setValue(formatTime(time));
  }, [time, setValue]);

  return (
    <div className='NumberInput'>
      <div
        className='numb-cont'
        onClick={() => {
          setDurationOpen(true);
        }}
      >
        {value}
      </div>
      <div className='arrow-cont-in'>
        <div
          className='cont'
          onClick={() => setTime((prevTime) => prevTime + interval)}
        >
          <img alt='' src={down} className='image' />
        </div>
        <div
          className='cont-2'
          onClick={() =>
            setTime((prevTime) => (prevTime > 0 ? prevTime - interval : 0))
          }
        >
          <img alt='' src={down} className='image' />
        </div>
      </div>
    </div>
  );
};

export default DurationInput;
