import React from "react";
import down from "../../../assets/down-white.png";
import "./NumberInputStyles.scss";

interface TimeInputProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setTimeOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const addMinutes = (time: string, minutes: number) => {
  const [hours, mins] = time.split(":").map(Number);
  const totalMinutes = hours * 60 + mins + minutes;
  const newHours = Math.floor(totalMinutes / 60) % 24;
  const newMinutes = totalMinutes % 60;
  return `${String(newHours).padStart(2, "0")}:${String(newMinutes).padStart(
    2,
    "0"
  )}`;
};

const TimeInput: React.FC<TimeInputProps> = ({
  value,
  setValue,
  setTimeOpen,
}) => {
  return (
    <div className='NumberInput'>
      <div
        className='numb-cont'
        onClick={() => {
          setTimeOpen(true);
        }}
      >
        {value}
      </div>
      <div className='arrow-cont-in'>
        <div className='cont' onClick={() => setValue(addMinutes(value, 15))}>
          <img
            alt=''
            src={down}
            className='image'
            style={{ transform: "rotate(180deg)" }}
          />
        </div>
        <div
          className='cont-2'
          onClick={() => setValue(addMinutes(value, -15))}
        >
          <img alt='' src={down} className='image' />
        </div>
      </div>
    </div>
  );
};

export default TimeInput;
