import axios from "axios";
import globalValues from "../../globalValues";
export const generateToken = async () => {
  try {
    const apiObject = {
      isLoginServerRequest: false,
      serviceName: "reservation",
    };
    const url = globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL;
    const tokenResponse = await axios({
      method: "POST",
      url: url,
      data: apiObject,
    });
    return tokenResponse.data.token;
  } catch (error) {
    console.error("Error In Generating Token", error);
  }
};
export const generateUserToken = async () => {
  try {
    const apiObject = {
      isLoginServerRequest: false,
      serviceName: "orgregistry",
    };
    let headers = {
      "Access-Control-Allow-Origin": "*",
    };
    const url = globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL;
    const tokenResponse = await axios({
      method: "POST",
      url: url,
      data: apiObject,
      headers: headers,
    });
    return tokenResponse.data.token;
  } catch (error) {
    console.error("Error In Generating Token", error);
  }
};
