import React from "react";
import "./CoverModalStyles.scss";
import close from "../../../../assets/close.png";

interface CoverModalProps {
  isOpen: boolean;
  onClose: () => void;
  cover: number;
  setCover: React.Dispatch<React.SetStateAction<number>>;
  isEdit: boolean;
}

const CoverModal: React.FC<CoverModalProps> = ({
  isOpen,
  onClose,
  cover,
  setCover,
  isEdit,
}) => {
  if (!isOpen) {
    return null;
  }
  const covers = Array.from({ length: 25 }, (_, i) => i + 1);
  return (
    <div className='cover-modal-overlay' onClick={onClose}>
      <div
        className='cover-modal-content'
        onClick={(e) => e.stopPropagation()}
        style={{ left: isEdit ? "37.2%" : "", top: isEdit ? "2rem" : "" }}
      >
        <div className='header'>
          <img alt='' src={close} onClick={onClose} />
        </div>
        <div className='content'>
          {covers.map((item, index) => {
            return (
              <div
                className={`item-cover ${cover === item ? "selected" : ""}`}
                key={index}
                onClick={() => {
                  setCover(item);
                  onClose();
                }}
              >
                <div className='gradient-text '>{item}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CoverModal;
