import React from "react";
import "./App.scss";
import BookingPageOne from "./view/pages/bookings/BookingPageOne";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BookingPageTwo from "./view/pages/bookings/BookingPageTwo";
import BookingPageThree from "./view/pages/bookings/BookingPageThree";
import Login from "./view/pages/auth/login/Login";
import ReservationListView from "./view/pages/auth/reservation/ReservationListView";
import ReservationFloorView from "./view/pages/auth/reservation/ReservationFloorView";
import NewReservation from "./view/pages/auth/reservation/NewReservation";

const App: React.FC = () => {
  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route
            path='/booking/slots/:locationId'
            element={<BookingPageOne />}
          />
          <Route
            path='/booking/customer-details'
            element={<BookingPageTwo />}
          />
          <Route path='/booking/confirmation' element={<BookingPageThree />} />
          <Route path='/' element={<Login />} />
          <Route
            path='/v1/booking/list-view'
            element={<ReservationListView />}
          />
          <Route
            path='/v1/booking/floor-view'
            element={<ReservationFloorView />}
          />
          <Route
            path='/v1/booking/new-reservation'
            element={<NewReservation />}
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
