import React from "react";
import home from "../../../assets/home.svg";
import link1 from "../../../assets/link1.svg";
import link2 from "../../../assets/link2.svg";
import link3 from "../../../assets/list.svg";
import link4 from "../../../assets/link4.svg";
import link5 from "../../../assets/list5.svg";
import link6 from "../../../assets/link6.svg";
import link7 from "../../../assets/link7.svg";
import arrow from "../../../assets/arrow.png";
import add from "../../../assets/add.svg";

import "./DrawerStyles.scss";
import { useNavigate } from "react-router-dom";

interface DrawerProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMenu: string;
}
const Drawer: React.FC<DrawerProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  selectedMenu,
}) => {
  const navigate = useNavigate();

  const ICONS = [
    // {
    //   img: link1,
    //   header: "Calender",
    //   type: "CALENDER",
    // },
    // {
    //   img: link2,
    //   header: "Table Reservation",
    //   type: "TABLE_RESERVATION",
    // },
    {
      img: link3,
      header: "View",
      type: "VIEW",
      subTypes: ["Floor", "List"],
    },
    // {
    //   img: link4,
    //   header: "Shift Management",
    //   type: "SHIFT_MANAGEMENT",
    // },
    // {
    //   img: link5,
    //   header: "Reservation Details",
    //   type: "RESERVATION_DETAILS",
    // },
    // {
    //   img: link6,
    //   header: "Pricing",
    //   type: "PRICING",
    // },
    // {
    //   img: link7,
    //   header: "Settings",
    //   type: "SETTINGS",
    // },
  ];
  const handleOnclick = (value: string) => {
    switch (value) {
      case "FLOOR":
        navigate("/v1/booking/floor-view");
        break;
      case "LIST":
        navigate("/v1/booking/list-view");
        break;
      case "ADD":
        navigate("/v1/booking/new-reservation");
        break;
      default:
        navigate("/v1/booking/list-view");
        break;
    }
  };
  return (
    <div className='Drawer'>
      <div className='homeIC'>
        <img alt='' src={home} /> WITMEG
      </div>
      <div
        className='add-cont'
        onClick={() => {
          handleOnclick("ADD");
        }}
      >
        <div className='addBtn'>
          <img alt='' src={add} />
        </div>
        New Reservation
      </div>
      {ICONS.map((icon, index) => {
        return (
          <div className='list'>
            <div
              className={
                (selectedMenu === "FLOOR" || selectedMenu === "LIST") &&
                icon.type === "VIEW"
                  ? "iconListSelected"
                  : "iconList"
              }
              key={index}
              onClick={() => {}}
            >
              <img alt='' src={icon.img} />
              <div> {icon.header}</div>
            </div>
            {icon.subTypes?.map((type, index) => {
              return (
                <div
                  key={index}
                  className={
                    selectedMenu === type.toUpperCase()
                      ? "subtype-selected "
                      : "subtype"
                  }
                  onClick={() => {
                    handleOnclick(type.toUpperCase());
                  }}
                >
                  {type}
                </div>
              );
            })}
          </div>
        );
      })}
      <div className='expand' onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
        <img alt='' src={arrow} />
      </div>
    </div>
  );
};
export default Drawer;
