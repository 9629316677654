import React from "react";
import "./ModalStyles.scss";
import img from "../../../assets/delete.png";
import Button from "../../button/Button";

interface ErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='modal-overlay' onClick={onClose}>
      <div className='modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='header'>Something Went Wrong</div>
        <div className='err-cont'>
          <img alt='' src={img} className='error' />
        </div>
        <div className='content'>booking failed</div>
        <div className='btn-container'>
          <Button text='Close' onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
