import React from "react";
import QRCode from "react-qr-code";
import "./QRComponentStyles.scss";

interface QRComponentProps {
  value: string;
}
const QRComponent: React.FC<QRComponentProps> = ({ value }) => {
  return (
    <div className='qr-container'>
      <QRCode
        size={256}
        style={{ height: "auto", maxWidth: "6rem", width: "6rem" }}
        value={value}
        viewBox={`0 0 256 256`}
        className='qr'
      />
    </div>
  );
};
export default QRComponent;
