import React from "react";
import home from "../../../assets/home.svg";
import add from "../../../assets/add.svg";
import link1 from "../../../assets/link1.svg";
import link2 from "../../../assets/link2.svg";
import link3 from "../../../assets/list.svg";
import link4 from "../../../assets/link4.svg";
import link5 from "../../../assets/list5.svg";
import link6 from "../../../assets/link6.svg";
import link7 from "../../../assets/link7.svg";
import arrow from "../../../assets/arrow.png";
import { useNavigate } from "react-router-dom";

import "./SideBarStyles.scss";
interface SideBarProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const SideBar: React.FC<SideBarProps> = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const navigate = useNavigate();
  const ICONS = [
    // {
    //   img: link1,
    //   header: "Calender",
    //   type: "CALENDER",
    // },
    // {
    //   img: link2,
    //   header: "Table Reservation",
    //   type: "TABLE_RESERVATION",
    // },
    {
      img: link3,
      header: "View",
      type: "VIEW",
    },
    // {
    //   img: link4,
    //   header: "Shift Management",
    //   type: "SHIFT_MANAGEMENT",
    // },
    // {
    //   img: link5,
    //   header: "Reservation Details",
    //   type: "RESERVATION_DETAILS",
    // },
    // {
    //   img: link6,
    //   header: "Pricing",
    //   type: "PRICING",
    // },
    // {
    //   img: link7,
    //   header: "Settings",
    //   type: "SETTINGS",
    // },
  ];
  return (
    <div className='SideBar'>
      <div className='homeIC'>
        <img alt='' src={home} />
      </div>
      <div
        className='addBtn'
        onClick={() => {
          navigate("/v1/booking/new-reservation");
        }}
      >
        <img alt='' src={add} />
      </div>
      {ICONS.map((icon, index) => {
        return (
          <div className='iconList' key={index}>
            <img alt='' src={icon.img} />
          </div>
        );
      })}
      <div className='expand' onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
        <img alt='' src={arrow} />
      </div>
    </div>
  );
};
export default SideBar;
