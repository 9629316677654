import React from "react";
import "./InputComponentStyles.scss";

interface InputProps {
  value: string;
  label: string;
  onChange: (value: string) => void;
  disabled: boolean;
  fontSize?: string;
  placeholder?: string | "";
}

const InputComponent: React.FC<InputProps> = ({
  value,
  label,
  onChange,
  disabled,
  fontSize,
  placeholder,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className='input-component'>
      {label !== "" && (
        <div className='label'>
          {label} <span style={{ color: "red", marginLeft: "0.2rem" }}>*</span>
        </div>
      )}
      <input
        type='text'
        id={label}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        className={disabled ? "disabled" : ""}
        style={{ fontSize }}
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputComponent;
