import React from "react";
import "./TableStyles.scss";
import locked from "../../../../assets/locked.svg";
interface TableProps {
  height: number;
  width: number;
  chairCount: number;
  type: "RECTANGLE" | "CIRCLE";
  name: string;
  isLocked?: boolean;
  status?: any;
  isView: boolean;
  isEdit: boolean;
}

const Table: React.FC<TableProps> = ({
  height,
  width,
  chairCount,
  type,
  name,
  isLocked,
  status,
  isView,
  isEdit,
}) => {
  const getTablePos = () => {
    let topCount, bottomCount;

    if (chairCount % 2 === 0) {
      topCount = bottomCount = chairCount / 2;
    } else {
      topCount = Math.ceil(chairCount / 2);
      bottomCount = Math.floor(chairCount / 2);
    }

    return { topCount, bottomCount };
  };
  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      className='Table'
    >
      <div className='top'>
        {Array.from({ length: getTablePos().topCount }).map((_, index) => {
          return (
            <div className='chair-wrapper' key={index}>
              <div className='chair' />
            </div>
          );
        })}
      </div>
      <div className='center' style={{ height: `${height - 30}px` }}>
        <div className='chair-cont'>
          <div className='chair' />
        </div>
        <div
          className='table-cont'
          style={{ borderRadius: type === "CIRCLE" ? "50%" : "" }}
        >
          <div
            className='inner-cont'
            style={{
              backgroundColor: !isView
                ? isEdit
                  ? ""
                  : isLocked
                  ? "#E8361E"
                  : ""
                : status
                ? `${status.Color}1A`
                : "",
              borderRadius: type === "CIRCLE" ? "50%" : "",
            }}
          >
            {!isView ? (
              isEdit ? (
                <div className='gradient-text'>{name}</div>
              ) : isLocked ? (
                <img alt='' src={locked} className='img' />
              ) : (
                <div className='gradient-text'>{name}</div>
              )
            ) : (
              <div className='gradient-text'>{name}</div>
            )}
          </div>
        </div>
        <div className='chair-cont'>
          <div className='chair' />
        </div>
      </div>
      <div className='bottom'>
        {Array.from({ length: getTablePos().bottomCount }).map((_, index) => {
          return (
            <div className='chair-wrapper' key={index}>
              <div className='chair' />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Table;
