import React, { useEffect, useState, useRef } from "react";
import "./FloorViewStyles.scss";
import FloorLayout from "../../../../components/ReservationComponents/floorLayout/FloorLayout";
import { getFloorDetails } from "../../../../../infra/apis/bookings/requests/Booking";
import { useDispatch, useSelector } from "react-redux";
import { setFloorData } from "../../../../../application/reducer/bookingSlice";
import { RootState } from "../../../../../application/store";
import BookingModal from "../../../../components/ReservationComponents/modal/bookingModal/BookingModal";
import FloorDetails from "../floorDetails/FloorDetails";
import TabelDetails from "../tableDetails/TableDetails";
import SelectedTables from "../tableDetails/SelectedTables";
import Alert from "../tableDetails/Alert";

interface FloorViewProps {
  date: string;
  isView?: boolean;
  time: string;
}

const FloorView: React.FC<FloorViewProps> = ({ date, isView, time }) => {
  const dispatch = useDispatch();
  const floorData = useSelector((state: RootState) => state.booking.floorData);
  const allocatedTables = useSelector(
    (state: RootState) => state.booking.allocatedTables
  );
  const [selectedFloor, setSelectedFloor] = useState<any>(null);
  const [selectedTable, setSelectedTable] = useState<any>(null);
  const [selectedTableDetails, setSelectedTableDetails] = useState<any>(null);
  const [selectedTables, setSelectedTables] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const parentRef = useRef<HTMLDivElement>(null);
  const [parentHeight, setParentHeight] = useState(540);

  useEffect(() => {
    getFloorDetails(date).then((response: any) => {
      setSelectedFloor(response[0]);
      dispatch(setFloorData(response));
    });
  }, [date]);

  useEffect(() => {
    const parent = parentRef.current;
    if (selectedFloor?.Tables?.length > 0) {
      if (parent) {
        const isOverflowingHeight = parent.scrollHeight > parent.clientHeight;
        if (isOverflowingHeight) {
          setParentHeight((prevHeight) => prevHeight + 540);
        }
      }
    } else {
      setParentHeight(540);
    }
  }, [selectedFloor, parentHeight]);
  console.log(selectedTableDetails, "selectedTableDetails");
  return (
    <div className='FloorView'>
      <div className='header-container'>
        {floorData.map((floor: any) => {
          return (
            <div
              className={`content ${
                selectedFloor?.FloorID === floor.FloorID
                  ? "content-selected "
                  : ""
              }`}
              onClick={() => setSelectedFloor(floor)}
            >
              {floor.DisplayName}
            </div>
          );
        })}
      </div>

      <div
        className='floor'
        ref={parentRef}
        style={{ height: `${parentHeight}px` }}
      >
        <FloorLayout
          items={
            selectedFloor
              ? selectedFloor?.Tables !== null
                ? selectedFloor?.Tables
                : []
              : []
          }
          setSelected={setSelectedTable}
          isView={isView}
          time={time}
        />
      </div>

      <BookingModal
        isOpen={selectedTable !== null ? true : false}
        onClose={() => {
          setSelectedTable(null);
        }}
        selected={selectedTable}
        setSelectedTableDetails={setSelectedTableDetails}
        setIsModalOpen={setIsModalOpen}
      />
      <div style={{ position: "relative" }}>
        {isModalOpen && (
          <FloorDetails
            isModalOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
            }}
            tableData={selectedTableDetails}
            setSelectedTables={setSelectedTables}
            setIsModalOpen={setIsModalOpen}
          />
        )}
        {selectedTables && (
          <TabelDetails
            isModalOpen={selectedTables}
            onClose={() => {
              setSelectedTables(false);
              setIsModalOpen(true);
            }}
            tableData={selectedTableDetails}
            setSelectedTable={setSelectedTableDetails}
          />
        )}
      </div>
    </div>
  );
};
export default FloorView;
