import React from "react";
import down from "../../../assets/down-white.png";
import "./NumberInputStyles.scss";
interface NumberInputProps {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  setCoversOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const NumberInput: React.FC<NumberInputProps> = ({
  value,
  setValue,
  setCoversOpen,
}) => {
  return (
    <div className='NumberInput'>
      <div
        className='numb-cont'
        onClick={() => {
          setCoversOpen(true);
        }}
      >
        {value}
      </div>
      <div className='arrow-cont-in'>
        <div
          className='cont'
          onClick={() => {
            if (value !== 25) {
              setValue((value) => value + 1);
            }
          }}
        >
          <img alt='' src={down} className='image' />
        </div>
        <div
          className='cont-2'
          onClick={() => {
            if (value !== 1) {
              setValue((value) => value - 1);
            }
          }}
        >
          <img alt='' src={down} className='image' />
        </div>
      </div>
    </div>
  );
};
export default NumberInput;
