import React from "react";
import "./DurationModalStyles.scss";
import close from "../../../../assets/close.png";

interface DurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  interval: number;
  setInterval: React.Dispatch<React.SetStateAction<number>>;
  isEdit: boolean;
}

const DurationModal: React.FC<DurationModalProps> = ({
  isOpen,
  onClose,
  interval,
  setInterval,
  isEdit,
}) => {
  if (!isOpen) {
    return null;
  }

  const generateTimeIntervals = () => {
    const intervals: number[] = [];
    const totalMinutes = 2 * 60;

    for (let minutes = 0; minutes <= totalMinutes; minutes += 15) {
      if (minutes !== 0) {
        intervals.push(minutes);
      }
    }

    return intervals;
  };
  const formatTime = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return `${hours > 0 ? `${hours}h ` : ""}${
      remainingMinutes > 0 ? `${remainingMinutes}m` : ""
    }`.trim();
  };

  return (
    <div className='duration-modal-overlay' onClick={onClose}>
      <div
        className='duration-modal-content'
        onClick={(e) => e.stopPropagation()}
        style={{ left: isEdit ? "37.2%" : "", top: isEdit ? "2rem" : "" }}
      >
        <div className='header'>
          <img alt='' src={close} onClick={onClose} />
        </div>
        <div className='content'>
          {generateTimeIntervals().map((item, index) => {
            return (
              <div
                className={`item-duration ${
                  interval ? (interval === item ? "selected" : "") : ""
                }`}
                key={index}
                onClick={() => {
                  setInterval(item);
                  onClose();
                }}
              >
                <div className='gradient-text '>{formatTime(item)}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DurationModal;
