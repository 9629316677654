import React, { useState, useEffect } from "react";
import "./ReservationStyles.scss";
import SideBar from "../../../components/ReservationComponents/sideBar/SideBar";
import TopBar from "../../../components/ReservationComponents/topBar/TopBar";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../application/store";
import {
  getBookingDetails,
  getShiftDetails,
} from "../../../../infra/apis/bookings/requests/Booking";
import {
  setBookingDetails,
  setShiftDetails,
} from "../../../../application/reducer/bookingSlice";
import { useNavigate } from "react-router-dom";
import Drawer from "../../../components/ReservationComponents/drawer/Drawer";
import ReservationFloorViewComponent from "./ReservationFloorViewComponent";
import FloorDetails from "./floorDetails/FloorDetails";
import TabelDetails from "./tableDetails/TableDetails";

const ReservationFloorView: React.FC = () => {
  const [value, setValue] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedShift, setSelectedShift] = useState<string>("All Shifts");
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedTable, setSelectedTable] = useState<any>(null);
  const [selectedTables, setSelectedTables] = useState<boolean>(false);

  const shiftData = useSelector(
    (state: RootState) => state.booking.shiftDetails
  );
  const bookingDetails = useSelector(
    (state: RootState) => state.booking.bookingDetails
  );
  const locationId = useSelector(
    (state: RootState) => state.booking.locationId
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let upcomingFilter = ["LATE", "NOT CONFIRMED", "CONFIRMED", "NO_ANSWER"];
  useEffect(() => {
    let userDetails = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails") || "")
      : "";
    if (userDetails === "" || userDetails === null) {
      navigate("/");
    } else {
      getData();
    }
  }, []);
  useEffect(() => {
    getData();
  }, [selectedDate, locationId]);
  const getData = () => {
    setLoading(true);
    getShiftDetails(locationId).then((shiftData) => {
      dispatch(setShiftDetails(shiftData));
      getBookingDetails(selectedDate).then((bookingData) => {
        dispatch(setBookingDetails(bookingData));
        setLoading(false);
      });
    });
  };

  const filterByName = (bookings: any) => {
    if (value === "") {
      return bookings;
    } else {
      return bookings.filter((item: any) =>
        item.CustomerDetails.FirstName.toLowerCase().includes(
          value.toLowerCase()
        )
      );
    }
  };
  const getUnsortedShifts = () => {
    if (shiftData === undefined || shiftData === null) {
      return [];
    } else {
      const shiftsWithBookings = shiftData.map((shift: any) => {
        let data = bookingDetails !== null ? bookingDetails : [];
        const associatedBookings = data.filter(
          (booking: any) =>
            booking.BookingDetails.Shift?.Id === shift.ShiftManagementID
        );
        return {
          Name: shift.Name,
          Bookings: associatedBookings,
        };
      });
      return shiftsWithBookings;
    }
  };
  const getshiftsWithBookings = () => {
    if (selectedShift === "All Shifts") {
      return getUnsortedShifts();
    } else {
      return getUnsortedShifts().filter(
        (shift: any) => shift.Name === selectedShift
      );
    }
  };
  const getSeatedBookings = (booking: any) => {
    if (selectedFilter === "") {
      return filterByName(booking);
    } else if (selectedFilter.toUpperCase() !== "SEATED") {
      return filterByName(booking).filter((bookingData: any) =>
        upcomingFilter.includes(bookingData.Status)
      );
    } else {
      return filterByName(booking).filter(
        (bookingData: any) => bookingData.Status === "SEATED"
      );
    }
  };
  console.log(selectedTables, "selectedTables");
  return (
    <div className='Reservation'>
      {isDrawerOpen ? (
        <Drawer
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          selectedMenu={"FLOOR"}
        />
      ) : (
        <SideBar
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      )}
      <div
        className='reservation-content'
        style={{ marginLeft: isDrawerOpen ? "16.25rem" : "5rem" }}
      >
        <TopBar
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          title='Floor View'
          details
        />
        {loading ? (
          <div className='loader' />
        ) : (
          <ReservationFloorViewComponent
            setSelectedShift={setSelectedShift}
            unsortedShifts={getUnsortedShifts()}
            selectedShift={selectedShift}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            getSeatedBookings={getSeatedBookings}
            getshiftsWithBookings={getshiftsWithBookings}
            value={value}
            setValue={setValue}
            setSelectedTable={setSelectedTable}
            setIsTableView={setIsModalOpen}
            selectedDate={selectedDate}
          />
        )}
      </div>
      {isModalOpen && (
        <FloorDetails
          isModalOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedTable(null);
          }}
          tableData={selectedTable}
          setSelectedTables={setSelectedTables}
          setIsModalOpen={setIsModalOpen}
        />
      )}
      {selectedTables && (
        <TabelDetails
          isModalOpen={selectedTables}
          onClose={() => {
            setIsModalOpen(true);
            setSelectedTables(false);
          }}
          tableData={selectedTable}
          setSelectedTable={setSelectedTable}
        />
      )}
    </div>
  );
};
export default ReservationFloorView;
