const {
  NODE_ENV,
  REACT_APP_TOKENSERVICE_URL,
  REACT_APP_RESERVATION_BASEURL,
  REACT_APP_USERSERVICE_URL,
  REACT_APP_ORAGNIZATION_BASEURL,
} = process.env;

const globalValues = {
  baseURLS: {
    REACT_APP_TOKENSERVICE_URL: REACT_APP_TOKENSERVICE_URL,
    REACT_APP_USERSERVICE_URL: REACT_APP_USERSERVICE_URL,
    REACT_APP_RESERVATION_BASEURL: REACT_APP_RESERVATION_BASEURL,
    REACT_APP_ORAGNIZATION_BASEURL: REACT_APP_ORAGNIZATION_BASEURL,
  },

  environment: {
    CURRENT_ENVIRONMENT: NODE_ENV,
    IS_DEVELOPMENT: NODE_ENV === "development",
  },
};
export default globalValues;
