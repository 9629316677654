import React from "react";
import "./ModalStyles.scss";
import img from "../../../assets/check.png";
import Button from "../../button/Button";

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOk: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  isOpen,
  onClose,
  onOk,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='modal-overlay' onClick={onClose}>
      <div className='modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='header'>Reservation successful!</div>
        <div className='err-cont'>
          <img alt='' src={img} className='error' />
        </div>
        <div className='content'>
          Your reservation has been successfully completed. Please refer to the
          floor view for detailed information about your booking.
        </div>
        <div className='btn-container'>
          <div className='close' onClick={onClose}>
            Close
          </div>
          <Button text='OK' onClick={onOk} />
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
