import React from "react";
import "./TextAreaStyles.scss";

interface TextAreaProps {
  value: string;
  label: string;
  onChange: (value: string) => void;
}

const TextArea: React.FC<TextAreaProps> = ({ value, label, onChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className='TextArea'>
      <div className='label'>{label}</div>
      <textarea id={label} value={value} onChange={handleChange} />
    </div>
  );
};

export default TextArea;
