import React from "react";
import FilterTab from "../../../components/ReservationComponents/filterTab/FilterTab";
import FloatingTab from "../../../components/ReservationComponents/floatingTab/FloatingTab";
import ShiftsView from "../../../components/ReservationComponents/shiftsView/ShiftsView";

interface ReservationListViewComponentProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  getshiftsWithBookings: any;
  setSelectedShift: React.Dispatch<React.SetStateAction<string>>;
  getUnsortedShifts: any;
  selectedShift: string;
  selectedFilter: string;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
  getSeatedBookings: any;
}

const ReservationListViewComponent: React.FC<
  ReservationListViewComponentProps
> = ({
  value,
  setValue,
  getshiftsWithBookings,
  setSelectedShift,
  getUnsortedShifts,
  selectedShift,
  selectedFilter,
  setSelectedFilter,
  getSeatedBookings,
}) => {
  return (
    <div className='list-view'>
      <FilterTab
        value={value}
        setValue={setValue}
        bookingDetails={getshiftsWithBookings()}
        setSelectedShift={setSelectedShift}
        unsortedShifts={getUnsortedShifts()}
        selectedShift={selectedShift}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
      <FloatingTab />
      <div className='table-view'>
        {getshiftsWithBookings().map((shift: any) => {
          return (
            <div>
              <ShiftsView
                shift={shift.Name}
                bookings={getSeatedBookings(shift.Bookings)}
                unsortedBookings={shift.Bookings}
                selectedFilter={selectedFilter}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ReservationListViewComponent;
