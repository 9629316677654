import axios from "axios";
import globalValues from "../../globalValues";
import { generateToken, generateUserToken } from "../../token/requests/Token";
export const userLogin = async (username: string, password: string) => {
  try {
    const token = await generateUserToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let data = {
          UserName: username,
          Password: password,
        };
        let url = `${globalValues.baseURLS.REACT_APP_USERSERVICE_URL}/v1/controlcenter/login`;
        await axios({
          method: "POST",
          url: url,
          headers,
          data: data,
        })
          .then(async (response) => {
            if (response.data.Status) {
              let userDetails = response.data;
              localStorage.setItem("userDetails", JSON.stringify(userDetails));
              let ids = response.data.OrgManagementData.map(
                (item: any) => item.OrganizationData.ID
              );
              getSubscriptionDetails(ids)
                .then((res: any) => {
                  let locationId = res[0].CloudLocationID;
                  localStorage.setItem("wrlId", locationId || "");
                  let managementData = response.data.OrgManagementData;
                  const organizationNameMap = new Map<string, string>();

                  // populate the organizationNameMap with organization IDs and names
                  managementData?.forEach((org: any) => {
                    organizationNameMap.set(
                      org.OrganizationData.ID,
                      org.OrganizationData.Name
                    );
                  });

                  // map for location IDs to organization IDs
                  const locationMap = new Map<string, string>();

                  res.forEach((loc: any) => {
                    locationMap.set(loc.CloudLocationID, loc.OrganizationID);
                  });
                  // Filter locations and include organization names
                  const matchingLocations = managementData?.flatMap(
                    (org: any) =>
                      org.OrganizationData.Companies.flatMap((company: any) =>
                        company.Locations.filter(
                          (location: any) =>
                            locationMap.has(location.ID) &&
                            locationMap.get(location.ID) ===
                              org.OrganizationData.ID
                        ).map((location: any) => ({
                          ...location,
                          OrganizationName: organizationNameMap.get(
                            org.OrganizationData.ID
                          ),
                        }))
                      )
                  );
                  localStorage.setItem("OCLIDs", JSON.stringify(res));
                  localStorage.setItem(
                    "locations",
                    JSON.stringify(matchingLocations)
                  );
                  resolve(200);
                })
                .catch((err) => {
                  localStorage.clear();
                  resolve(404);
                });
            } else {
              resolve(401);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In Login", error);
  }
};
export const getSubscriptionDetails = async (Ids: string[]) => {
  try {
    const token = await generateToken();
    if (token) {
      return new Promise(async (resolve, reject) => {
        let headers = {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        };
        let data = {
          IDs: Ids,
        };
        let url = `${globalValues.baseURLS.REACT_APP_RESERVATION_BASEURL}/v1/witmeg-reservations/location/validate`;
        await axios({
          method: "POST",
          url: url,
          headers,
          data: data,
        })
          .then((response) => {
            if (response.data.Status) {
              resolve(response.data.Result);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } catch (error) {
    console.error("Error In Login", error);
  }
};
