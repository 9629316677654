import React from "react";
import "./FloatingTabStyles.scss";
import arrow from "../../../assets/arrow.png";
import tomorrow from "../../../assets/tomorrow.png";
import res from "../../../assets/res.svg";
import ppl from "../../../assets/ppl.svg";

const FloatingTab: React.FC = () => {
  return (
    <div className='FloatingTab'>
      <div className='inner-container'>
        <div className='deatils-cont'>
          <div className='ic-conatiner-1'>
            <img alt='' src={tomorrow} />
          </div>
          <div className='text-cont'>
            <div className='header'>Upcoming</div>
            <div className='sub'>Reservations</div>
          </div>
          <div className='reserve-cont'>
            <img alt='' src={res} />
            {/* <div className='count-not'>1</div> */}
          </div>
          <div className='cover-cont'>
            <img alt='' src={ppl} />
            {/* <div className='count-not'>4</div> */}
          </div>
        </div>
        <div className='arrow-container'>
          <img alt='' src={arrow} />
        </div>
      </div>
    </div>
  );
};
export default FloatingTab;
