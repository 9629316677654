import React from "react";
import danger from "../../../../assets/close.png";
import warning from "../../../../assets/alert.png";
import close from "../../../../assets/close.png";

interface AlertProps {
  type: string;
  content: string;
}
const Alert: React.FC<AlertProps> = ({ type, content }) => {
  return (
    <div className={`alert-${type}`}>
      <div className='left-content'>
        <div className='cancel'>
          <img
            alt=''
            src={type === "danger" ? danger : warning}
            className={type}
          />
        </div>
        {content}
      </div>
      {/* <div className='right-content'>
        <img alt='' src={close} />
      </div> */}
    </div>
  );
};
export default Alert;
