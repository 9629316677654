import React from "react";
//styles
import "./LogoContainerStyles.scss";
import logo from "../../assets/logo.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../application/store";
import moment from "moment";
import loc from "../../assets/loc.png";

const LogoContainer: React.FC = () => {
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  const openingHours = organizationDetails?.OpeningHours?.filter(
    (day: any) => !day.Closed
  ).map((day: any) => ({
    day: day.Day,
    hours: day?.OpenHours?.map(
      (hours: any) => `${hours.StartTime} - ${hours.EndTime}`
    ).join(", "),
  }));
  return (
    <div className='LogoContainer'>
      <div className='container'>
        <div className='logo-cont'>
          <img alt='' src={logo} className='logo' />
        </div>

        <div className='det'>
          <div className='location'>
            <img alt='' src={loc} />
            {organizationDetails?.LocationDetails?.Name}
          </div>
          <div className='time-contaner'>
            <div className='head'>The business hours</div>
            {openingHours?.map((day: any, index: number) => {
              return (
                <div
                  className={`times ${
                    moment().format("dddd") === day.day ? "bold" : ""
                  }`}
                  key={index}
                >
                  <div>{day.day}</div>
                  <div>
                    {day.hours.split(",")[0]}
                    <br />
                    {day.hours.split(",")[1]}
                  </div>
                </div>
              );
            })}
          </div>
          <div className='details'>
            <div className='bookings'>for bookings</div>
            <div className='sub'>{organizationDetails?.Website}</div>
            <div className='sub'>
              {organizationDetails?.ContactDetails?.PhoneCode}{" "}
              {organizationDetails?.ContactDetails?.Number}
            </div>
          </div>
          <div className='address'>
            {organizationDetails?.Address?.AddressLine01},
            {organizationDetails?.Address?.City},{" "}
            {organizationDetails?.Address?.PostalCode}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LogoContainer;
